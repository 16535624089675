<script lang="ts" setup></script>

<template>
  <div class="auth-header container">
    <AppLangSwitcher />
  </div>
</template>

<style lang="scss" scoped>

  .auth-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

</style>
